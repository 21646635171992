<template>
  <div :bordered="false" v-if="projectInfo.project">
    <div style="border: 1px solid rgb(235, 237, 240)">
      <a-page-header
        style="border: 0px solid rgb(235, 237, 240)"
        title="项目信息"
        @back="() => this.$router.back()"
      />
      <a-descriptions style="margin-top: 10px; margin-left: 20px;">
        <a-descriptions-item label="项目编号">
          {{ projectInfo.project[0].number }}
        </a-descriptions-item>
        <a-descriptions-item label="项目名称">
          {{ projectInfo.project[0].name }}
        </a-descriptions-item>
        <a-descriptions-item label="主体企业">
          {{ projectInfo.project[0].host_corporation }}
        </a-descriptions-item>
        <a-descriptions-item label="交易品种">
          {{ projectInfo.project[0].category }}
        </a-descriptions-item>
        <a-descriptions-item label="信用">
          {{ (projectInfo.project[0].credit).toFixed(2) | numberFormat }}
        </a-descriptions-item>
        <a-descriptions-item label="现金">
          {{ (projectInfo.project[0].cash).toFixed(2) | numberFormat }}
        </a-descriptions-item>
        <a-descriptions-item label="起止时间">
          {{ projectInfo.project[0].start_date }} 至 {{ projectInfo.project[0].end_date }}
        </a-descriptions-item>
      </a-descriptions>
    </div>
    <a-spin :spinning="loading">
      <div>
        <div v-for="(item) in sortedProjectContentList" :key="(item.id + item.content_type)">
          <template v-if="item.content_type === 'sell_contract'">
            <a-row type="flex" class="row_item">
              <a-col :span="2" style="padding-top: 15px">
                <span>购销合同</span>
              </a-col>
              <a-col :span="22">
                <div :title="item.name">
                  <span class="desc">编号：{{ item.number }}</span>
                  <span class="desc">名称：{{ item.name }}</span>
                  <span class="desc">客户：{{ item.client }}</span>
                  <span class="desc">已开启审批：{{ item.is_approval_needed ? '是' : '否' }}</span>
                  <span class="desc">审批状态：<span :class="statusText[item.status < 0 ? 0 : item.status][0]">{{ item.is_approval_needed ? (dictionaries.approval_result[item.status] ? dictionaries.approval_result[item.status] : '无') : un_approval_needed_text }}</span></span>
                  <span class="desc" v-action:add>已存档：
                    <a-popconfirm
                      title="确定存档吗?"
                      :disabled="true"
                      @confirm="() => handleArchived(item, 'sell_contract')">
                      <a-switch checked-children="是" un-checked-children="否" :checked="false" :disabled="true"/>
                    </a-popconfirm>
                  </span>
                  <span class="desc" v-if="item.create_by">创建人：{{ item.create_by }}</span>
                  <span class="desc">创建时间：{{ item.create_time | dayjs }}</span>
                  <br><span class="desc" v-if="item.remark">备注：{{ item.remark }}</span>
                  <div>
                    <a-collapse :bordered="false" style="padding: 1px;">
                      <template #expandIcon="props">
                        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                      </template>
                      <a-collapse-panel key="1" header="关键点" :style="customStyle" v-if="item.key_point.length > 0">
                        <p v-for="(point) in item.key_point" :key="point.id">{{ point.key }}：<span style="color: #00A0E9">{{ point.value }}</span></p>
                      </a-collapse-panel>
                      <a-collapse-panel key="2" header="附件" :style="customStyle" v-if="item.attachment.length > 0">
                        <a v-for="(attachment) in item.attachment" :key="attachment.id" :href="attachment.url" target="_blank" class="desc"><a-icon type="file" theme="filled" /> {{ attachment.name }}</a>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
              </a-col>
            </a-row>
          </template>
          <template v-else-if="item.content_type === 'contract'">
            <a-row type="flex" class="row_item">
              <a-col :span="2" style="padding-top: 15px">
                <span>普通合同</span>
              </a-col>
              <a-col :span="22">
                <div :title="item.name">
                  <span class="desc">编号：{{ item.number }}</span>
                  <span class="desc">名称：{{ item.name }}</span>
                  <span class="desc">客户：{{ item.client }}</span>
                  <span class="desc">已开启审批：{{ item.is_approval_needed ? '是' : '否' }}</span>
                  <span class="desc">审批状态：<span :class="statusText[item.status < 0 ? 0 : item.status][0]">{{ item.is_approval_needed ? (dictionaries.approval_result[item.status] ? dictionaries.approval_result[item.status] : '无') : un_approval_needed_text }}</span></span>
                  <span class="desc" v-action:add>已存档：
                    <a-popconfirm
                      title="确定存档吗?"
                      :disabled="true"
                      @confirm="() => handleArchived(item, 'contract')">
                      <a-switch checked-children="是" un-checked-children="否" :checked="false" :disabled="true"/>
                    </a-popconfirm>
                  </span>
                  <span class="desc" v-if="item.create_by">创建人：{{ item.create_by }}</span>
                  <span class="desc">创建时间：{{ item.create_time | dayjs }}</span>
                  <br><span class="desc" v-if="item.remark">备注：{{ item.remark }}</span>
                  <div v-if="item.attachment.length > 0">
                    <a-collapse :bordered="false">
                      <template #expandIcon="props">
                        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                      </template>
                      <a-collapse-panel key="2" header="附件" :style="customStyle">
                        <a v-for="(attachment) in item.attachment" :key="attachment.id" :href="attachment.url" target="_blank" class="desc"><a-icon type="file" theme="filled" /> {{ attachment.name }}</a>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
              </a-col>
            </a-row>
          </template>
          <template v-else-if="item.content_type === 'material'">
            <a-row type="flex" class="row_item">
              <a-col :span="2" style="padding-top: 15px">
                <span>项目资料</span>
              </a-col>
              <a-col :span="22">
                <div :title="item.name">
                  <span class="desc">编号：{{ item.number }}</span>
                  <span class="desc">名称：{{ item.name }}</span>
                  <span class="desc">类型：{{ dictionaries.trade_material_type[item.type] }}</span>
                  <span class="desc" v-action:add>已存档：
                    <a-popconfirm
                      title="确定存档吗?"
                      :disabled="true"
                      @confirm="() => handleArchived(item, 'project_material')">
                      <a-switch checked-children="是" un-checked-children="否" :checked="false" :disabled="true"/>
                    </a-popconfirm>
                  </span>
                  <span class="desc" v-if="item.create_by">创建人：{{ item.create_by }}</span>
                  <span class="desc">创建时间：{{ item.create_time | dayjs }}</span>
                  <br><span class="desc" v-if="item.remark">备注：{{ item.remark }}</span>
                  <div v-if="item.attachment.length > 0">
                    <a-collapse :bordered="false">
                      <template #expandIcon="props">
                        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                      </template>
                      <a-collapse-panel key="2" header="附件" :style="customStyle">
                        <a v-for="(attachment) in item.attachment" :key="attachment.id" :href="attachment.url" target="_blank" class="desc"><a-icon type="file" theme="filled" /> {{ attachment.name }}</a>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
              </a-col>
            </a-row>
          </template>
          <template v-else-if="item.content_type === 'inventory'">
            <a-row type="flex" class="row_item">
              <a-col :span="2" style="padding-top: 15px">
                <span>出入库</span>
              </a-col>
              <a-col :span="22">
                <div :title="item.name">
                  <span class="desc">仓库：{{ item.warehouse }}</span>
                  <span class="desc">数量：{{ item.count | numberFormat }}</span>
                  <span class="desc">单位：{{ dictionaries.unit[item.unit] ? dictionaries.unit[item.unit] : '--' }}</span>
                  <span class="desc">单价：{{ (item.unit_price).toFixed(2) | numberFormat }}</span>
                  <span class="desc">货币：{{ dictionaries.currency[item.currency] ? dictionaries.currency[item.currency] : '--' }}</span>
                  <span class="desc">预估人民币单价：{{ (item.unit_price_cny).toFixed(2) | numberFormat }}</span>
                  <span class="desc">已开启审批：{{ item.is_approval_needed ? '是' : '否' }}</span>
                  <span class="desc">审批状态：<span :class="statusText[item.status < 0 ? 0 : item.status][0]">{{ item.is_approval_needed ? (dictionaries.approval_result[item.status] ? dictionaries.approval_result[item.status] : '无') : un_approval_needed_text }}</span></span>
                  <span class="desc" v-action:add>已存档：
                    <a-popconfirm
                      title="确定存档吗?"
                      :disabled="true"
                      @confirm="() => handleArchived(item, 'inventory')">
                      <a-switch checked-children="是" un-checked-children="否" :checked="false" :disabled="true"/>
                    </a-popconfirm>
                  </span>
                  <span class="desc">已调整：{{ item.is_adjustment ? '是' : '否' }}</span>
                  <span class="desc" v-if="item.create_by">创建人：{{ item.create_by }}</span>
                  <span class="desc">创建时间：{{ item.create_time | dayjs }}</span>
                  <div>
                    <a-collapse :bordered="false">
                      <template #expandIcon="props">
                        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                      </template>
                      <a-collapse-panel key="1" header="附件" :style="customStyle" v-if="item.attachment.length > 0">
                        <a v-for="(attachment) in item.attachment" :key="attachment.id" :href="attachment.url" target="_blank" class="desc"><a-icon type="file" theme="filled" /> {{ attachment.name }}</a>
                      </a-collapse-panel>
                      <a-collapse-panel key="2" header="出入库调整" :style="customStyle" v-if="false">
                        <span class="desc">仓库：{{ item.adjustment[0].warehouse }}</span>
                        <span class="desc">数量：{{ item.adjustment[0].count | numberFormat }}</span>
                        <span class="desc">单位：{{ dictionaries.unit[item.adjustment[0].unit] }}</span>
                        <span class="desc">预估人民币单价：{{ (item.adjustment[0].unit_price_cny).toFixed(2) | numberFormat }} </span>
                        <span class="desc" v-if="item.adjustment[0].adjustment_attachment.length > 0">附件：
                          <a v-for="(ada) in item.adjustment[0].adjustment_attachment" :key="ada.id" :href="ada.url" target="_blank" class="desc"><a-icon type="file" theme="filled" /> {{ ada.name }}</a>
                        </span>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
              </a-col>
            </a-row>
          </template>
          <template v-else-if="item.content_type === 'inventory_adjustment'">
            <a-row type="flex" class="row_item">
              <a-col :span="2" style="padding-top: 15px">
                <span>出入库调整</span>
              </a-col>
              <a-col :span="22">
                <div :title="item.name">
                  <span class="desc">仓库：{{ item.warehouse }}</span>
                  <span class="desc">数量：{{ item.count | numberFormat }}</span>
                  <span class="desc">单位：{{ dictionaries.unit[item.unit] }}</span>
                  <span class="desc">预估人民币单价：{{ (item.unit_price_cny).toFixed(2) | numberFormat }} </span>
                  <span class="desc">已开启审批：{{ item.is_approval_needed ? '是' : '否' }}</span>
                  <span class="desc">审批状态：<span :class="statusText[item.status < 0 ? 0 : item.status][0]">{{ item.is_approval_needed ? (dictionaries.approval_result[item.status] ? dictionaries.approval_result[item.status] : '无') : un_approval_needed_text }}</span></span>
                  <span class="desc" v-action:add>已存档：
                    <a-popconfirm
                      title="确定存档吗?"
                      :disabled="true"
                      @confirm="() => handleArchived(item, 'inventory_adjustment')">
                      <a-switch checked-children="是" un-checked-children="否" :checked="item.is_archived" :disabled="true"/>
                    </a-popconfirm>
                  </span>
                  <span class="desc">已调整：{{ item.is_adjustment ? '是' : '否' }}</span>
                  <span class="desc" v-if="item.create_by">创建人：{{ item.create_by }}</span>
                  <span class="desc">创建时间：{{ item.create_time | dayjs }}</span>
                  <span style="color: #2a99ff; cursor: pointer" class="desc" @click="watchInventory(item)" >查看被调整库存</span>
                  <div>
                    <a-collapse :bordered="false">
                      <template #expandIcon="props">
                        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                      </template>
                      <a-collapse-panel key="1" header="附件" :style="customStyle" v-if="item.attachment.length > 0">
                        <a v-for="(attachment) in item.attachment" :key="attachment.id" :href="attachment.url" target="_blank" class="desc"><a-icon type="file" theme="filled" /> {{ attachment.name }}</a>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
              </a-col>
            </a-row>
          </template>
          <template v-else-if="item.content_type === 'payment'">
            <a-row type="flex" class="row_item">
              <a-col :span="2" style="padding-top: 15px">
                <span>付款</span>
              </a-col>
              <a-col :span="22">
                <div :title="item.name">
                  <span class="desc">客户：{{ item.client }}</span>
                  <span class="desc">金额：{{ (item.amount).toFixed(2) | numberFormat }}</span>
                  <span class="desc">货币：{{ dictionaries.currency[item.currency] }}</span>
                  <span class="desc">预估人民币金额：{{ (item.amount_cny).toFixed(2) | numberFormat }}</span>
                  <span class="desc">支付方式：{{ dictionaries.trade_payment_method[item.method] }}</span>
                  <span class="desc">已开启审批：{{ item.is_approval_needed ? '是' : '否' }}</span>
                  <span class="desc">审批状态：<span :class="statusText[item.status < 0 ? 0 : item.status][0]">{{ item.is_approval_needed ? (dictionaries.approval_result[item.status] ? dictionaries.approval_result[item.status] : '无') : un_approval_needed_text }}</span></span>
                  <span class="desc" v-action:add>已存档：
                    <a-popconfirm
                      title="确定存档吗?"
                      :disabled="true"
                      @confirm="() => handleArchived(item, 'payment')">
                      <a-switch checked-children="是" un-checked-children="否" :checked="false" :disabled="true"/>
                    </a-popconfirm>
                  </span>
                  <span class="desc">支付时间：{{ item.pay_time | dayjs }}</span>
                  <span class="desc" v-if="item.create_by">创建人：{{ item.create_by }}</span>
                  <span class="desc">创建时间：{{ item.create_time | dayjs }}</span><br>
                  <span class="desc" v-if="item.remark">备注：{{ item.remark }}</span>
                  <div v-if="item.attachment.length > 0">
                    <a-collapse :bordered="false">
                      <template #expandIcon="props">
                        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                      </template>
                      <a-collapse-panel key="1" header="附件" :style="customStyle">
                        <a v-for="(a) in item.attachment" :key="a.id" :href="a.url" target="_blank" class="desc"><a-icon type="file" theme="filled" /> {{ a.name }}</a>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
              </a-col>
            </a-row>
          </template>
          <template v-else-if="item.content_type === 'collection'">
            <a-row type="flex" class="row_item">
              <a-col :span="2" style="padding-top: 15px">
                <span>收款</span>
              </a-col>
              <a-col :span="22">
                <div :title="item.name">
                  <span style="display: block;">
                    <span class="desc">客户：{{ item.client }}</span>
                    <span class="desc">金额：{{ (item.amount).toFixed(2) | numberFormat }}</span>
                    <span class="desc">货币：{{ dictionaries.currency[item.currency] }}</span>
                    <span class="desc">预估人民币金额：{{ (item.amount_cny).toFixed(2) | numberFormat }}</span>
                    <span class="desc">支付方式：{{ dictionaries.trade_payment_method[item.method] }}</span>
                    <span class="desc">已开启审批：{{ item.is_approval_needed ? '是' : '否' }}</span>
                    <span class="desc">审批状态：<span :class="statusText[item.status < 0 ? 0 : item.status][0]">{{ item.is_approval_needed ? (dictionaries.approval_result[item.status] ? dictionaries.approval_result[item.status] : '无') : un_approval_needed_text }}</span></span>
                    <span class="desc">已存档：
                      <a-popconfirm
                        title="确定存档吗?"
                        :disabled="true"
                        @confirm="() => handleArchived(item, 'collection')">
                        <a-switch checked-children="是" un-checked-children="否" :checked="item.is_archived" :disabled="true"/>
                      </a-popconfirm>
                    </span>
                    <span class="desc">支付时间：{{ item.pay_time | dayjs }}</span>
                    <span class="desc" v-if="item.create_by">创建人：{{ item.create_by }}</span>
                    <span class="desc">创建时间：{{ item.create_time | dayjs }}</span>
                  </span>
                  <span class="desc" v-if="item.remark">备注：{{ item.remark }}</span>
                  <div v-if="item.attachment.length > 0">
                    <a-collapse :bordered="false">
                      <template #expandIcon="props">
                        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                      </template>
                      <a-collapse-panel key="1" header="附件" :style="customStyle">
                        <a v-for="(a) in item.attachment" :key="a.id" :href="a.url" target="_blank" class="desc"><a-icon type="file" theme="filled" /> {{ a.name }}</a>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
              </a-col>
            </a-row>
          </template>
          <template v-else-if="item.content_type === 'fapiao'">
            <a-row type="flex" class="row_item">
              <a-col :span="2" style="padding-top: 15px">
                <span>发票</span>
              </a-col>
              <a-col :span="22">
                <div :title="item.name">
                  <span class="desc">客户：{{ item.client }}</span>
                  <span class="desc">方式：{{ dictionaries.trade_fapiao_method[item.method] }}</span>
                  <span class="desc">金额：{{ (item.total_price).toFixed(2) | numberFormat }}</span>
                  <span class="desc">税额：{{ (item.total_tax).toFixed(2) | numberFormat }}</span>
                  <span class="desc">已开启审批：{{ item.is_approval_needed ? '是' : '否' }}</span>
                  <span class="desc">审批状态：<span :class="statusText[item.status < 0 ? 0 : item.status][0]">{{ item.is_approval_needed ? (dictionaries.approval_result[item.status] ? dictionaries.approval_result[item.status] : '无') : un_approval_needed_text }}</span></span>
                  <span class="desc" v-action:add>已存档：
                    <a-popconfirm
                      title="确定存档吗?"
                      :disabled="true"
                      @confirm="() => handleArchived(item, 'fapiao')">
                      <a-switch checked-children="是" un-checked-children="否" :checked="false" :disabled="true"/>
                    </a-popconfirm>
                  </span>
                  <span class="desc" v-if="item.create_by">创建人：{{ item.create_by }}</span>
                  <span class="desc">创建时间：{{ item.create_time | dayjs }}</span>
                  <br><span class="desc" v-if="item.remark">备注：{{ item.remark }}</span>
                  <div>
                    <a-collapse :bordered="false">
                      <template #expandIcon="props">
                        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                      </template>
                      <a-collapse-panel key="2" header="附件" :style="customStyle" v-if="item.attachment.length > 0">
                        <a v-for="(attachment) in item.attachment" :key="attachment.id" :href="attachment.url" target="_blank" class="desc"><a-icon type="file" theme="filled" /> {{ attachment.name }}</a>
                      </a-collapse-panel>
                      <a-collapse-panel key="3" header="详情" :style="customStyle" v-if="item.detail.length > 0">
                        <div v-for="(d) in item.detail" :key="d.id" >
                          <span class="desc">名称：{{ d.name }}</span>
                          <span class="desc">数量：{{ d.count | numberFormat }}</span>
                          <span class="desc">单价：{{ (d.unit_price ).toFixed(2) | numberFormat }}</span>
                          <span class="desc">单位：{{ dictionaries.unit[d.unit] }}</span>
                          <span class="desc">货币：{{ dictionaries.currency[d.currency] }}</span>
                          <span class="desc">费率：{{ d.rate }}%</span>
                          <span class="desc">税额：{{ (d.total_tax).toFixed(2) | numberFormat }}</span>
                          <span class="desc">金额：{{ (d.total_price).toFixed(2) | numberFormat }}</span>
                        </div>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
              </a-col>
            </a-row>
          </template>
          <template v-else-if="item.content_type === 'credit_letter'">
            <a-row type="flex" class="row_item">
              <a-col :span="2" style="padding-top: 15px">
                <span>信用证</span>
              </a-col>
              <a-col :span="22">
                <div :title="item.name">
                  <span class="desc">编号：{{ item.number }}</span>
                  <span class="desc">主体企业：{{ item.host_corporation }}</span>
                  <span class="desc">金额：{{ (item.amount).toFixed(2) | numberFormat }}</span>
                  <span class="desc">货币：{{ dictionaries.currency[item.currency] }}</span>
                  <span class="desc">预估人民币金额：{{ (item.amount_cny).toFixed(2) | numberFormat }}</span>
                  <span class="desc">已开启审批：{{ item.is_approval_needed ? '是' : '否' }}</span>
                  <span class="desc">审批状态：<span :class="statusText[item.status < 0 ? 0 : item.status][0]">{{ item.is_approval_needed ? (dictionaries.approval_result[item.status] ? dictionaries.approval_result[item.status] : '无') : un_approval_needed_text }}</span></span>
                  <span class="desc" v-action:add>已存档：
                    <a-popconfirm
                      title="确定存档吗?"
                      :disabled="true"
                      @confirm="() => handleArchived(item, 'credit_letter')">
                      <a-switch checked-children="是" un-checked-children="否" :checked="false" :disabled="true"/>
                    </a-popconfirm>
                  </span>
                  <span class="desc">已完成：
                    <a-popconfirm
                      title="确定已完成?"
                      :disabled="true"
                      @confirm="() => handleCreditLetterDone(item)">
                      <a-switch checked-children="是" un-checked-children="否" :checked="item.is_done" :disabled="true"/>
                    </a-popconfirm>
                  </span>
                  <span class="desc" v-if="item.create_by">创建人：{{ item.create_by }}</span>
                  <span class="desc">创建时间：{{ item.create_time | dayjs }}</span><br>
                  <span class="desc" v-if="item.remark">备注：{{ item.remark }}</span>
                  <div>
                    <a-collapse :bordered="false">
                      <template #expandIcon="props">
                        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                      </template>
                      <a-collapse-panel key="2" header="附件" :style="customStyle" v-if="item.attachment.length > 0">
                        <a v-for="(attachment) in item.attachment" :key="attachment.id" :href="attachment.url" target="_blank" class="desc"><a-icon type="file" theme="filled" /> {{ attachment.name }}</a>
                      </a-collapse-panel>
                      <a-collapse-panel key="3" header="详情" :style="customStyle" v-if="item.detail.length > 0">
                        <div v-for="(d) in item.detail" :key="d.id">
                          <span class="desc">银行：{{ d.bank }}</span>
                          <span class="desc">金额：{{ (d.amount).toFixed(2) | numberFormat }}</span>
                          <span class="desc">信用证货币到人民币的汇率：{{ d.to_cny_rate }}</span>
                          <span class="desc">预估人民币金额：{{ (d.amount_cny).toFixed(2) | numberFormat }}</span>
                          <span class="desc">融资利率：{{ d.financing_rate }}%</span>
                          <span class="desc">类别：{{ d.type }}</span>
                          <span class="desc">费用：{{ d.fee }}</span>
                          <span class="desc">额度占用：{{ d.limit }}</span>
                          <span class="desc">开始时间：{{ d.start_date }}</span>
                          <span class="desc" v-if="false">总费用：{{ (d.total_amount).toFixed(2) | numberFormat }}</span>
                          <span class="desc" v-if="false">截止时间：{{ d.start_date }}- {{ d.end_date }}</span>
                          <span class="desc">已完成：
                            <a-popconfirm
                              title="确定已完成?"
                              :disabled="true"
                              @confirm="() => handleCreditLetterDetailDone(d)">
                              <a-switch checked-children="是" un-checked-children="否" :checked="d.is_done" :disabled="true"/>
                            </a-popconfirm>
                          </span>
                          <br><span class="desc" v-if="d.remark">备注：{{ d.remark }}</span>
                        </div>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
              </a-col>
            </a-row>
          </template>
          <template v-else-if="item.content_type === 'credit_letter_repayment'">
            <a-row type="flex" class="row_item">
              <a-col :span="2" style="padding-top: 15px">
                <span>信用证还款</span>
              </a-col>
              <a-col :span="22">
                <div :title="item.name">
                  <span class="desc" v-if="item.number">还证编号：{{ item.number }}</span>
                  <span class="desc" v-if="item.credit_letter">信用证编号：{{ item.credit_letter }}</span>
                  <span class="desc" v-if="item.bank">银行：{{ item.bank }}</span>
                  <span class="desc" v-if="item.bank_credit_letter_number">银行信用证编号：{{ item.bank_credit_letter_number }}</span>
                  <span class="desc" v-if="item.amount">金额：{{ (item.amount).toFixed(2) | numberFormat }}</span>
                  <span class="desc" v-if="item.to_cny_rate">信用证货币到人民币的汇率：{{ item.to_cny_rate }}</span>
                  <span class="desc" v-if="item.amount_cny">预估人民币金额：{{ (item.amount_cny).toFixed(2) | numberFormat }}</span>
                  <span class="desc" v-if="item.financing_rate">融资利率：{{ item.financing_rate }}%</span>
                  <span class="desc" v-if="item.total_amount">总金额：{{ (item.total_amount).toFixed(2) | numberFormat }}</span>
                  <span class="desc" v-if="item.repayment_date">还款日期：{{ item.repayment_date }}</span>
                  <span class="desc">是否押汇：{{ item.is_bill_negotiation ? '是' : '否' }}</span>
                  <span class="desc">已开启审批：{{ item.is_approval_needed ? '是' : '否' }}</span>
                  <span class="desc">审批状态：<span :class="statusText[item.status < 0 ? 0 : item.status][0]">{{ item.is_approval_needed ? (dictionaries.approval_result[item.status] ? dictionaries.approval_result[item.status] : '无') : un_approval_needed_text }}</span></span>
                  <span class="desc" v-action:add>已存档：
                    <a-popconfirm
                      title="确定存档吗?"
                      :disabled="true"
                      @confirm="() => handleArchived(item, 'credit_letter_repayment')">
                      <a-switch checked-children="是" un-checked-children="否" :checked="false" :disabled="true"/>
                    </a-popconfirm>
                  </span>
                  <span class="desc" v-if="item.create_by">创建人：{{ item.create_by }}</span>
                  <span class="desc">创建时间：{{ item.create_time | dayjs }}</span>
                  <br><span class="desc" v-if="item.remark">备注：{{ item.remark }}</span>
                  <div>
                    <a-collapse :bordered="false">
                      <template #expandIcon="props">
                        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                      </template>
                      <a-collapse-panel key="2" header="附件" :style="customStyle" v-if="item.attachment.length > 0">
                        <a v-for="(attachment) in item.attachment" :key="attachment.id" :href="attachment.url" target="_blank" class="desc"><a-icon type="file" theme="filled" /> {{ attachment.name }}</a>
                      </a-collapse-panel>
                      <a-collapse-panel key="3" header="详情" :style="customStyle" v-if="false">
                        <div v-for="(d) in item.detail" :key="d.id">
                          <span class="desc">银行：{{ d.bank }}</span>
                          <span class="desc">金额：{{ (d.amount).toFixed(2) | numberFormat }}</span>
                          <span class="desc">信用证货币到人民币的汇率：{{ d.to_cny_rate }}</span>
                          <span class="desc">预估人民币金额：{{ (d.amount_cny).toFixed(2) | numberFormat }}</span>
                          <span class="desc">融资利率：{{ d.financing_rate }}%</span>
                          <span class="desc">总金额：{{ (d.total_amount).toFixed(2) | numberFormat }}</span>
                          <span class="desc">还款日期：{{ d.repayment_date }}</span><br>
                          <span class="desc" v-if="d.remark">备注：{{ d.remark }}</span>
                        </div>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
              </a-col>
            </a-row>
          </template>
        </div>
        <a-drawer
          title="库存"
          placement="right"
          :closable="false"
          v-if="visibleDrawer"
          :visible="visibleDrawer"
          @close="onClose"
          width="500">
          <div v-if="inventoryAdjustmentObj !== undefined">
            <span class="desc_item_drawer">仓库：{{ inventoryAdjustmentObj.warehouse }}</span><br>
            <span class="desc_item_drawer">数量：{{ inventoryAdjustmentObj.count | numberFormat }}</span><br>
            <span class="desc_item_drawer">单位：{{ $Dictionaries.unit[inventoryAdjustmentObj.unit] === undefined ? '-' : $Dictionaries.unit[inventoryAdjustmentObj.unit] }}</span><br>
            <span class="desc_item_drawer">预估人民币单价：{{ (inventoryAdjustmentObj.unit_price_cny).toFixed(2) | numberFormat }} </span><br>
            <span class="desc_item_drawer">已开启审批：{{ inventoryAdjustmentObj.is_approval_needed ? '是' : '否' }}</span><br>
            <span class="desc_item_drawer">审批状态：<span :class="statusText[inventoryAdjustmentObj.status < 0 ? 0 : inventoryAdjustmentObj.status][0]">{{ inventoryAdjustmentObj.is_approval_needed ? ($Dictionaries.approval_result[inventoryAdjustmentObj.status] ? $Dictionaries.approval_result[inventoryAdjustmentObj.status] : '无') : un_approval_needed_text }}</span></span><br>
            <span class="desc_item_drawer">是否已调整：{{ inventoryAdjustmentObj.is_adjustment ? '是' : '否' }}</span><br>
            <span class="desc_item_drawer">已存档：{{ inventoryAdjustmentObj.is_archived ? '是' : '否' }}</span><br>
            <span class="desc" v-if="inventoryAdjustmentObj.create_by">创建人：{{ inventoryAdjustmentObj.create_by }}</span>
            <span class="desc_item_drawer">创建时间：{{ inventoryAdjustmentObj.create_time | dayjs }}</span>
            <div v-if="false">
              <a-collapse :bordered="false">
                <template #expandIcon="props">
                  <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                </template>
                <a-collapse-panel key="1" header="附件" :style="customStyle" v-if="inventoryAdjustmentObj.attachment.length > 0">
                  <a v-for="(attachment) in inventoryAdjustmentObj.attachment" :key="attachment.id" :href="attachment.url" target="_blank" class="desc_item_drawer"><a-icon type="file" theme="filled" /> {{ attachment.name }}</a>
                </a-collapse-panel>
              </a-collapse>
            </div>
          </div>
        </a-drawer>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import { project_detail } from '@/api/trade_project'
import { createProcessResult, getApproveToDoDetail, getForms } from '@/api/trade_design'
import {
  credit_letter_update
} from '@/api/trade_credit_letter'
import { credit_letter_detail_update } from '@/api/trade_credit_letter_detail'

export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      loading: true,
      visibleDrawer: false,
      drawerLoading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      un_approval_needed_text: '未开启审批',
      currentComponent: '',
      // 创建窗口控制
      inventoryAdjustmentVisible: false,
      visible: false,
      mdl: {},
      inventoryMdl: {},
      statusText: {
        0: ['none', '无'],
        1: ['waiting', '待审批'],
        2: ['pass', '通过'],
        3: ['un_pass', '驳回']
      },
      inventoryAdjustmentObj: undefined,
      projectInfo: {},
      projectContentList: [],
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '主体企业',
          dataIndex: 'host_corporation'
        },
        {
          title: '交易品种',
          dataIndex: 'category'
        },
        {
          title: '编号',
          dataIndex: 'number'
        },
        {
          title: '名称',
          dataIndex: 'name'
        },
        {
          title: '信用',
          dataIndex: 'credit'
        },
        {
          title: '现金',
          dataIndex: 'cash'
        },
        {
          title: '起止时间',
          dataIndex: 'start_date',
          customRender: (text, record) => text === '1970-01-01' ? '' : text + '到' + record.end_date
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '180px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      selectedRowKeys: [],
      selectedRows: [],
      customStyle: 'background: #f7f7f7;border-radius: 4px;margin-bottom: 1px;border: 0;overflow: hidden',
      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    this.mdl.id = this.$route.query.record
    this.projectDetailInfo()
  },
  computed: {
    sortedProjectContentList () {
      return [...this.projectContentList].sort((a, b) => new Date(a.create_time) - new Date(b.create_time))
    }
  },
  methods: {
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd (component) {
      const date = new Date()
      const hours = date.getHours() > 9 ? date.getHours() : '0' + date.getHours()
      const minutes = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()
      this.mdl = { number: this.projectInfo.project[0].number + hours + minutes }
      this.currentComponent = component
      this.visible = true
    },
    handleAddSellContract () {
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs[this.currentComponent].form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleInventoryAdjustAddCancel () {
      this.inventoryAdjustmentVisible = false
      const form = this.$refs.inventoryAdjustmentCreateForm.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/trade/project/' + record.id })
    },
    handleClient (record) {
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/trade/project/' + record.id + '/client', query: { record: record.host_corporation } })
    },
    handleRemark (record) {
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/trade/project/' + record.id + '/remark', query: { record: record.host_corporation } })
    },
    handleDetail (record) {
      console.log(record)
    },
    handleDelete (record, type) {
      this.loading = true
      this.deleteByType(type)(record.id).then(res => {
        if (res.code === 1000) {
          this.projectDetailInfo()
        } else {
          this.loading = false
        }
      })
    },
    handleArchived (record, type) {
      this.loading = true
      this.archivedByType(type)({ ids: [record.id], is_archived: true }).then(res => {
        if (res.code === 1000) {
          this.projectDetailInfo()
        } else {
          this.loading = false
        }
      })
    },
    projectDetailInfo () {
      if (!this.loading) {
        this.loading = true
      }
      getApproveToDoDetail({ id: this.$route.query.id, business_type: this.$route.query.type, data_id: this.$route.query.data_id }).then(res => {
        if (res.code === 1000) {
          project_detail({ project_id: res.project_data_id }).then(res => {
            console.log(res)
            this.projectInfo = res.data
            this.projectContentList = []
            if (this.$route.query.type === 'trade_sell_contract') {
              this.projectContentList.push(...this.projectInfo.sell_contract_list.filter(item => {
                return item.id === Number(this.$route.query.data_id)
              }).map(item => {
                item.content_type = 'sell_contract'
                return item
              }))
            } else if (this.$route.query.type === 'trade_contract') {
              this.projectContentList.push(...this.projectInfo.contract_list.filter(item => {
                return item.id === Number(this.$route.query.data_id)
              }).map(item => {
                item.content_type = 'contract'
                return item
              }))
            } else if (this.$route.query.type === 'trade_material') {
              this.projectContentList.push(...this.projectInfo.material_list.filter(item => {
                return item.id === Number(this.$route.query.data_id)
              }).map(item => {
                item.content_type = 'material'
                return item
              }))
            } else if (this.$route.query.type === 'trade_inventory') {
              this.projectContentList.push(...this.projectInfo.inventory_list.filter(item => {
                return item.id === Number(this.$route.query.data_id)
              }).map(item => {
                item.content_type = 'inventory'
                return item
              }))
            } else if (this.$route.query.type === 'trade_inventory_adjustment') {
              const inventory_adjustment_list = this.projectInfo.inventory_adjustment_list.filter(item => {
                return item.id === Number(this.$route.query.data_id)
              }).map(item => {
                item.content_type = 'inventory_adjustment'
                return item
              })
              this.projectContentList.push(...inventory_adjustment_list)
            } else if (this.$route.query.type === 'trade_payment') {
              this.projectContentList.push(...this.projectInfo.payment_list.filter(item => {
                return item.id === Number(this.$route.query.data_id)
              }).map(item => {
                item.content_type = 'payment'
                return item
              }))
            } else if (this.$route.query.type === 'trade_collection') {
              this.projectContentList.push(...this.projectInfo.collection_list.filter(item => {
                return item.id === Number(this.$route.query.data_id)
              }).map(item => {
                item.content_type = 'collection'
                return item
              }))
            } else if (this.$route.query.type === 'trade_fapiao') {
              this.projectContentList.push(...this.projectInfo.fapiao_list.filter(item => {
                return item.id === Number(this.$route.query.data_id)
              }).map(item => {
                item.content_type = 'fapiao'
                return item
              }))
            } else if (this.$route.query.type === 'trade_credit_letter') {
              this.projectContentList.push(...this.projectInfo.credit_letter_list.filter(item => {
                return item.id === Number(this.$route.query.data_id)
              }).map(item => {
                item.content_type = 'credit_letter'
                return item
              }))
            } else if (this.$route.query.type === 'trade_credit_letter_repayment') {
              this.projectContentList.push(...this.projectInfo.credit_letter_repayment_list.filter(item => {
                return item.id === Number(this.$route.query.data_id)
              }).map(item => {
                item.content_type = 'credit_letter_repayment'
                return item
              }))
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    approvalNeeded (record, type, approval_again = false) {
      this.loading = true
      const is_approval_needed = approval_again ? true : !record.is_approval_needed
      this.approvalNeededByType(type)({ is_approval_needed: is_approval_needed }, record.id).then((res) => {
        if (res.code === 1000) {
          this.projectDetailInfo()
          if (is_approval_needed) {
            this.geneApprovalInfo(res.data.id, type, approval_again)
          }
        } else {
          this.loading = false
        }
      })
    },
    geneApprovalInfo (id, type, approval_again) {
      this.createContractApproval(id, type, approval_again)
    },
    createContractApproval (id, type, approval_again) {
      getForms({ is_stop: 0, template_type: type, data_id: id }).then(res => {
        const shenpi_list = res.data.entries
        if (shenpi_list && shenpi_list.length > 0) {
          for (let i = 0, len = shenpi_list.length; i < len; i++) {
            const item = shenpi_list[i]
            const settings = JSON.parse(item.settings)
            if (settings) {
              const notify = settings.notify
              if (notify) {
                const types = notify.types
                if (types) {
                  if (types.type === type) {
                    this.createProcessNotice(item.id, id, type, approval_again)
                    break
                  }
                }
              }
            }
          }
        } else {
          this.$message.warning('先创建审批模板')
          // this.createProcessNotice(-1, ids, type)
        }
      })
    },
    createProcessNotice (template_id, ids, type, approval_again = false) {
      const data = {
        'data_ids': [ids],
        'template_id': template_id,
        'template_type': type,
        'approval_again': approval_again
      }
      createProcessResult(data).then(res => {
        console.log(res)
        if (res.code === 1000) {
          this.projectDetailInfo()
        }
      })
    },
    handleEditRecord (record, type) {
      this.$router.push({ path: '/trade/' + type + '/' + record.id, query: { record: record.host_corporation } })
    },
    handleCreditLetterDone (record) {
      if (record.is_approval_needed && record.status === 1) {
        this.$message.warning('审批还未结束')
        return
      }
      credit_letter_update({ is_done: true }, record.id).then(res => {
        if (res.code === 1000) {
          this.projectDetailInfo()
        }
      })
    },
    handleCreditLetterDetailDone (record) {
      credit_letter_detail_update({ is_done: true }, record.id).then(res => {
        if (res.code === 1000) {
          this.projectDetailInfo()
        }
      })
    },
    getInventoryNumber (inventory_id) {
      let index = 0
      this.sortedProjectContentList.forEach((item, i) => {
        if (item.id === inventory_id && item.content_type === 'inventory') {
          index = i + 1
        }
      })
      return index
    },
    watchInventory (record) {
      console.log(record)
      const matchInventory = this.projectInfo.inventory_list.filter(item => {
        return item.id === record.inventory_id
      }).map(item => {
        item.content_type = 'inventory'
        return item
      })
      console.log(matchInventory)
      if (matchInventory !== undefined && matchInventory.length > 0) {
        this.inventoryAdjustmentObj = matchInventory[0]
        this.visibleDrawer = true
        console.log(this.inventoryAdjustmentObj)
      }
    },
    onClose () {
      this.visibleDrawer = false
    }
  }
}
</script>
<style scoped>
  .desc {
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
  }
  .row_item {
    border-bottom: 1px solid #eeeeee;
    padding: 10px 20px;
    background-color: #f7f7f7;
    margin-top: 5px;
    border-radius: 10px;
  }
  .content {
    color: #00A0E9;
    font-weight: bolder
  }
  .none {
    color: #333333;
  }
  .waiting {
    color: yellowgreen;
  }
  .pass {
    color: green;
  }
  .un_pass {
    color: red;
  }
  .desc_item_drawer {
    padding-left: 20px;
    height: 50px;
    line-height: 50px;
  }
</style>
